import moment from "moment";
import { IERCProtocolsElement } from "../types/ERCProtocolsElement";
import { IERCProtocolsCardElement } from "../types/ERCProtocolsCardElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/expertReviewCommission/fields";
import { IHistoricalNoteFile, IHistoricalNoteElement } from "@monorepo/inventory/src/views/HistoricalNotesView/types/element";

export const convertApiItemToUi = (
  item: IERCProtocolsElement
): IERCProtocolsElement & {
  oikShortName: string;
  status: string;
  fundNumber: string;
  userAvailableRoles: string;
  approvedDate: string;
  noteFiles: IHistoricalNoteFile[];
  noteGuid: string;
  noteFundCode: string;
  notePeriod: string;
  noteCreateDate: string;
  noteTitle: string;
  noteId: number;
} => {
  return {
    ...item,
    oikShortName: item.oikDto?.name,
    fundNumber: item.fundDto?.number,
    status: item.commissionStatus?.title ?? "",
    approvedDate: item?.approvedDate ? moment(item?.approvedDate).format("YYYY-MM-DD HH:mm") : "-",
    createDateTime: item?.createDateTime ? moment(item?.createDateTime).format("YYYY-MM-DD HH:mm") : "-",
    userAvailableRoles: item?.availableActions || "",
    [fields.CATEGORY]: item.category?.title || "",
    [fields.KIND]: item.kind?.title || "",
    [fields.TYPE]: item.type?.title || "",
    noteFiles: item.historicalNoteList?.[0]?.fileList || [],
    noteGuid: item.historicalNoteList?.[0]?.guid || "",
    noteFundCode: item.historicalNoteList?.[0]?.fundCode || "",
    notePeriod: item.historicalNoteList?.[0]?.period ? moment(item.historicalNoteList?.[0]?.period).format("YYYY-MM-DD") : "",
    noteCreateDate: item.historicalNoteList?.[0]?.createDate ? moment(item.historicalNoteList?.[0]?.createDate).format("YYYY-MM-DD") : "",
    noteTitle: item.historicalNoteList?.[0]?.title || "",
    noteId: item.historicalNoteList?.[0]?.id || 0,
  };
};

export const convertApiItemToCardUi = (
  item: IERCProtocolsCardElement
): IERCProtocolsCardElement & {
  oikShortName: string;
  fundNumber: string;
  noteFiles: IHistoricalNoteFile[];
  noteGuid: string;
  noteFundCode: string;
  notePeriod: string;
  noteCreateDate: string;
  noteTitle: string;
  noteId: number;
} => ({
  ...item,
  ...convertApiItemToUi(item),
});

export const convertApiHistoricalNoteItemToCardUi = (noteItem: IHistoricalNoteElement) => ({
  ...noteItem,
  type: "historical-note",
  approvedState: noteItem.approvedState?.title,
  yearPeriod: noteItem?.yearPeriod?.join("-") ?? "",
  children: [
    {
      historicalNoteId: noteItem.id,
      type: "file-list",
      loading: false,
      fileList:
        noteItem.fileList?.map((fileItem) => ({
          ...fileItem,
          historicalNote: noteItem,
          type: fileItem.type?.title,
          format: fileItem.format?.title,
          previewStatus: fileItem.previewStatus?.title,
        })) ?? [],
    },
  ],
  [fields.KIND]: noteItem.kind?.title,
});
