


































import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import TreeNode from "@monorepo/uikit/src/components/tableViews/TreeNode.vue";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import EadStatus from "@monorepo/inventory/src/views/EadView/components/EadStatus.vue";
import InventoryStatus from "@monorepo/uikit/src/components/tableViews/InventoryCountItemsStatus.vue";
import moment from "moment";
import { IDigitalSignatures, IEadDocumentFiles } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import { mapActions } from "vuex";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import { useDynamicTree } from "@monorepo/utils/src/composables/useDynamicTree";

interface caseDtoItem {
  oik: { id: number; code: string; name: string };
  oikCode: string;
  receivedEadCount: number;
  totalEadCount: number;
  year: string;
  fundCode: string;
  index: number;
  guid: string;
  header: string;
  id: number;
  number: string;
  archiveCode: string;
  archiveCipher: string;
  date: string;
  digitalSignatures: IDigitalSignatures[];
  ead: {
    createDate: string;
    digitalSignatures: IDigitalSignatures[];
    files: IEadDocumentFiles[];
    guid: string;
    number: number;
    id: number;
    title: string;
    archiveCipher: string;
    oik: { id: number; code: string; name: string };
    registrationDate: string;
    status: string;
    storageExpirationDate: string;
    storagePeriod: number;
  }[];
}

export default defineComponent({
  name: "CaseTree",
  components: {
    TreeNode,
    EadStatus,
    InventoryStatus,
    InfiniteScroll,
  },
  props: {
    cardModeResult: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    headers(): Record<
      string,
      { key: string; title: string; tooltip: string; width: string; isNoWrap?: boolean; slotName?: string; isLink?: boolean }[]
    > {
      const documentNumber = this.cardModeResult === CardMode.DEFAULT ? "70px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "250px" : "300px";
      const documentStatus = this.cardModeResult === CardMode.DEFAULT ? "50px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "70px" : "70px";
      const documentName = this.cardModeResult === CardMode.DEFAULT ? "164px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "258px" : "400px";

      const caseName = this.cardModeResult === CardMode.DEFAULT ? "100px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "200px" : "200px";
      const caseArchive = this.cardModeResult === CardMode.DEFAULT ? "126px" : this.cardModeResult === CardMode.THREE_QUARTHER ? "300px" : "300px";

      return {
        case: [
          // { key: "doc", slotName: "doc", title: "Документы", tooltip: "Получено документов/Всего документов", width: "100px" },
          { key: "archive", title: "Архивный шифр", tooltip: "Архивный шифр", width: caseArchive },
          { key: "name", title: "Заголовок", tooltip: "Заголовок дела", width: caseName, isLink: true },
          { key: "fundNumber", title: "Фонд", tooltip: "Номер фонда", width: "100px" },
          { key: "index", title: "Индекс дела", tooltip: "Индекс дела", width: "100px" },
          { key: "type", title: "Вид", tooltip: "Вид дела", width: "100px" },
          { key: "numberOfPages", title: "Листов", tooltip: "Количество листов", width: "100px" },
          { key: "status", title: "Статус", tooltip: "Статус", width: "120px" },
        ],
        document: [
          { key: "number", title: "Номер", tooltip: "Порядковый номер ЭАД", width: documentNumber, isNoWrap: false },
          { key: "archive", title: "Архивный шифр", tooltip: "Архивный шифр", width: "120px", isNoWrap: false },
          { key: "name", title: "Наименование", tooltip: "Наименование ЭАД", width: documentName, isNoWrap: true, isLink: true },
          { key: "regNumber", title: "Рег. номер", tooltip: "Регистрационный номер ЭАД в СЭД", width: "100px", isNoWrap: true },
          { key: "date", title: "Дата регистрации в СЭД", tooltip: "Дата регистрации в СЭД", width: "160px", isNoWrap: true },
        ],
      };
    },
    // treeItems(): Record<string, unknown>[] {
    //   return (
    //     this.element?.caseDtoList?.map((item: caseDtoItem) => ({
    //       id: item.id,
    //       title: item.number,
    //       isCase: true,
    //       isFolder: true,
    //       doc: `${item.receivedEadCount} / ${item.totalEadCount}`,
    //       name: item.header,
    //       receivedEadCount: item.receivedEadCount,
    //       totalEadCount: item.totalEadCount,
    //       archive: item.archiveCipher,
    //       fundNumber: item.fundCode,
    //       index: item.index,
    //       children:
    //         item.ead?.map((eadItem, index) => ({
    //           id: eadItem.id,
    //           isCommonHeader: !index,
    //           isHideHeader: index,
    //           number: eadItem.number,
    //           status: eadItem.status,
    //           name: eadItem.title,
    //           archive: eadItem.archiveCipher,
    //           regNumber: eadItem.id,
    //           date: moment(eadItem.createDate).format("YYYY-MM-DD"),
    //           isDoc: true,
    //         })) || [],
    //     })) || []
    //   );
    // },
  },
  data() {
    return {
      open: [],
      active: [],
      folder: `${environmentVariables.BASE_URL}assets/images/common/foleder.svg`,
      treeItems: [] as any[],
    };
  },
  methods: {
    ...mapActions("ExpertComissionView", ["getCaseTreeData"]),
    async fetchChildren(item: any) {
      this.openedItems = [...this.openedItems, item];
      if (item.totalLength && item.totalLength <= item.children) {
        return;
      }

      if (item.isCase) {
        await this.fetchEads(item);
      }
    },
    convertCaseItemToUi(item: any, index: number) {
      return {
        id: item.id,
        title: item.number,
        isCase: true,
        isFolder: true,
        caseId: `${item.id}${index}`,
        // doc: `${item.receivedEadCount} / ${item.totalEadCount}`,
        name: item.header,
        receivedEadCount: item.receivedEadCount,
        totalEadCount: item.totalEadCount,
        archive: item.archiveCipher,
        fundNumber: item.fundCode,
        index: item.index,
        children: [],
        type: item.storageKind?.title || "",
        numberOfPages: item.numberOfPages || "",
        status: item.status?.title || "",
      };
    },
    convertSectionToUi(section: any) {
      return {
        id: section.id,
        isSection: true,
        isFolder: true,
        name: section.name,
        children: (section.caseList || [])
          .map((item: any, index: number) => this.convertCaseItemToUi(item, index))
          .concat((section.subSection || []).map(this.convertSectionToUi)),
      };
    },
    handleCaseTreeData() {
      return this.getCaseTreeData({ id: this.id }).then(({ data }) => {
        this.treeItems = (data.caseList || [])
          .map((item: any, index: number) => this.convertCaseItemToUi(item, index))
          .concat((data.sectionList || []).map(this.convertSectionToUi));
        return { data: this.treeItems };
      });
    },
    openInventory({ header, item }: { header: { isLink: boolean }; item: { name: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/inventory/case",
          query: {
            header: item.name,
            isOpenModal: "1",
          },
        });
      }
    },

    openEad({ header, item }: { header: { isLink: boolean }; item: { name: string } }) {
      if (header.isLink) {
        if (header.isLink) {
          this.$router.push({ path: "/inventory/ead", query: { title: item.name, isOpenModal: "1" } });
        }
      }
    },
  },
  watch: {
    id() {
      this.treeItems = [];
      this.open = [];
      this.active = [];
    },
  },
  setup(props, { root }) {
    const fetchEads = async (item: any) => {
      const { data, totalLength } = await root.$store.dispatch("ExpertComissionView/getEadTreeData", {
        length: item.children?.length || 0,
        id: item.id,
      });

      item.totalLength = totalLength;
      item.children = [
        ...(item.children || []),
        ...data.map((eadTreeItem: any, index: number) => {
          return {
            id: index,
            isDoc: true,
            number: eadTreeItem.number,
            name: eadTreeItem.title,
            archive: eadTreeItem.archiveCipher,
            status: eadTreeItem.status,
            date: eadTreeItem.registrationDate ? moment(eadTreeItem.registrationDate as string).format("YYYY-MM-DD HH:mm") : "",
            caseIndex: eadTreeItem.caseIndex,
            regNumber: eadTreeItem.id,
          };
        }),
      ].map((item, index) => {
        return {
          ...item,
          isCommonHeader: !index,
          isHideHeader: index,
        };
      });
    };

    const handleLoad = (item: any) => {
      if (item.totalLength && item.totalLength <= item.children.length) {
        return;
      }

      if (item.isCase) {
        fetchEads(item);
      }
    };

    const { isLoadingOpenedItems, openedItems } = useDynamicTree({ handleLoad });

    return {
      isLoadingOpenedItems,
      openedItems,
      fetchEads,
    };
  },
});
