
























import type { IHistoricalNoteElement } from "@monorepo/inventory/src/views/HistoricalNotesView/types/element";
import { PropType, defineComponent, ref, watch, computed } from "@vue/composition-api";
import { fileItemHeaders } from "@monorepo/utils/src/variables/projectsData/historicalNotesView/treeNodeElements";
import { convertApiHistoricalNoteItemToCardUi } from "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/utils/convertApiItemToUi";
import { getQuery } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import TreeNode from "@monorepo/uikit/src/components/tableViews/TreeNode.vue";

export default defineComponent({
  name: "HistoricalNotesTree",
  components: { TreeNode },
  props: {
    data: {
      type: Array as PropType<IHistoricalNoteElement[]>,
      default: () => [],
    },
    cardModeResult: {
      type: String as PropType<CardMode>,
      default: CardMode.DEFAULT,
    },
  },

  setup(props, context) {
    const { root } = context;

    const historicalNoteList = ref<ReturnType<typeof convertApiHistoricalNoteItemToCardUi>[]>([]);

    const guidHeaderWidth = computed(() => {
      switch (props.cardModeResult) {
        case CardMode.DEFAULT:
          return "120px";
        default:
          return undefined;
      }
    });

    const titleHeaderWidth = computed(() => {
      switch (props.cardModeResult) {
        case CardMode.DEFAULT:
          return "164px";
        default:
          return undefined;
      }
    });

    const historicalNoteHeaders = computed(() => {
      return [
        { key: "guid", title: "Идентификатор", width: guidHeaderWidth.value, isShowContentTooltip: true, isLink: true },
        { key: "title", title: "Наименование", width: titleHeaderWidth.value, isShowContentTooltip: true },
        { key: "fundCode", title: "Фонд" },
        { key: "kind", title: "Признак" },
        { key: "yearPeriod", title: "Период" },
        { key: "approvedState", title: "Статус" },
      ];
    });

    const exportFiles = async (item: ReturnType<typeof convertApiHistoricalNoteItemToCardUi>["children"][number]) => {
      try {
        item.loading = true;
        await getQuery(QUERY_PATH.GET_HISTORICAL_NOTES_LIST_ZIP_LIST, { ids: [item.historicalNoteId] });
      } catch (e) {
        console.error(e);
      } finally {
        item.loading = false;
      }
    };

    const openHistoricalNote = ({ header, item }: { header: { isLink: boolean }; item: { guid: number } }) => {
      if (header.isLink) {
        root.$router.push({
          path: "/inventory/historical-notes",
          query: {
            guid: item.guid.toString(),
            isOpenModal: "1",
          },
        });
      }
    };

    watch(
      props.data,
      (nv) => {
        historicalNoteList.value = nv.map(convertApiHistoricalNoteItemToCardUi);
      },
      { immediate: true, deep: true }
    );

    return {
      openHistoricalNote,
      historicalNoteHeaders,
      fileItemHeaders,
      historicalNoteList,
      exportFiles,
    };
  },
});
