import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

export interface ExpertReviewCommissionFields {
  INVENTORY_ID: string;
  CHECKBOX: string;
  HEADER: string;
  NUMBER: string;
  YEAR: string;
  FUND_NUMBER: string;
  OIK_SHORT_NAME: string;
  CATEGORY: string;
  KIND: string;
  TYPE: string;
  STATUS: string;
  APPROVE_DATE: string;
  HISTORICAL_REFERENCE: string;
  COMMENT: string;
  USER_AVAILABLE_ROLES: string;
  COMMENT_UPDATE_USER: string;
  CREATE_DATE_TIME: string;
  COMMENT_UPDATE_DATE: string;

  FILTER_NUMBER: string;
  FILTER_OIK: string;
  FILTER_FUND: string;
  FILTER_YEAR: string;
  FILTER_STATUS: string;
  FILTER_HEADER: string;
  FILTER_KIND: string;
  FILTER_AVAILABLE_ACTION: string;
  FILTER_TYPE: string;
  FILTER_CATEGORY: string;
  FILTER_CREATE_DATE_TIME_FROM: string;
  FILTER_CREATE_DATE_TIME_TO: string;

  NOTE_GUID: string;
  NOTE_FUND_CODE: string;
  NOTE_PERIOD: string;
  NOTE_CREATE_DATE: string;
  NOTE_TITLE: string;
}

export const fields: ExpertReviewCommissionFields = {
  INVENTORY_ID: "id",
  CHECKBOX: headerCheckboxValue,
  HEADER: "header",
  NUMBER: "number",
  YEAR: "year",
  CATEGORY: "category",
  KIND: "kind",
  TYPE: "type",
  USER_AVAILABLE_ROLES: "userAvailableRoles",
  APPROVE_DATE: "approvedDate",
  FUND_NUMBER: "fundNumber",
  OIK_SHORT_NAME: "oikShortName",
  STATUS: "status",
  HISTORICAL_REFERENCE: "historicalNote",
  COMMENT: "comment",
  COMMENT_UPDATE_USER: "lastModifiedBy",
  COMMENT_UPDATE_DATE: "lastModifiedDateTime",
  CREATE_DATE_TIME: "createDateTime",

  // filters
  FILTER_NUMBER: "inventoryNumber",
  FILTER_OIK: "oikShortName",
  FILTER_FUND: "inventoryFund",
  FILTER_YEAR: "inventoryYear",
  FILTER_STATUS: "status",
  FILTER_HEADER: "inventoryTitle",
  FILTER_AVAILABLE_ACTION: "availableAction",
  FILTER_KIND: "kind",
  FILTER_TYPE: "type",
  FILTER_CATEGORY: "category",
  FILTER_CREATE_DATE_TIME_FROM: "createDateTimeFrom",
  FILTER_CREATE_DATE_TIME_TO: "createDateTimeTo",

  NOTE_GUID: "noteGuid",
  NOTE_FUND_CODE: "noteFundCode",
  NOTE_PERIOD: "notePeriod",
  NOTE_CREATE_DATE: "noteCreateDate",
  NOTE_TITLE: "noteTitle",
};
